import React from 'react'
import './home.css'
import Icon from '../elmrose.png'

function Home() {
    return (
    <>
        <div className="banner">
            <div className="hero">
                <img src={Icon} className='App-icon' alt='free-game-fever-icon' />
            </div>
            <div className="header">
                <h1>Elmrose</h1>
            </div>
        </div>
    </>
    )
}

export default Home
