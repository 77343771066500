import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import './App.css'
import Home from './screens/Home.js'
import PrivacyPolicy from './screens/PrivacyPolicy.js'

function App() {
  return (
    <div className='App'>
        <Router>
           <Routes>
             <Route path='/' element={<Home />} />
             <Route path='/privacy-policy' element={<PrivacyPolicy />} />
           </Routes>
       </Router>
    </div>
  )
}

export default App
